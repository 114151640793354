<template>
  <base-form-layout
    :title="`${action} ${$t('product.part', 1)}`"
    cols="12"
    lg="8"
    offset-lg="2"
    xl="6"
    offset-xl="3"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <ItemEndProductEditForm :item="itemProduct" />
    </v-form>
  </base-form-layout>
</template>

<script>
import ItemEndProductEditForm from './components/ItemEndProductEditForm'
import { ITEM_END_PRODUCT } from '@/models/item.end.product'
import { FormMixin } from '@/mixins/form.mixin'
import { DocumentMixin } from '@/mixins/document.mixin.js'
export default {
  components: {
    ItemEndProductEditForm
  },
  mixins: [FormMixin, DocumentMixin],
  props: {
    itemProductId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      itemProduct: ITEM_END_PRODUCT(),
      isLoading: false
    }
  },
  computed: {
    action() {
      return this.$t(this.itemProductId ? 'actions.edit' : 'actions.create')
    }
  },
  async created() {
    if (this.itemProductId) this.itemProduct = await this.fetchItemProduct()
    this.isLoading = false
  },
  methods: {
    async fetchItemProduct() {
      const { data } = await this.$store.dispatch(
        'itemEndProduct/fetch',
        this.itemProductId
      )
      return data
    },
    async onSubmit() {
      this.beforeSubmit = false
      this.isLoading = true
      if (this.itemProductId) {
        await this.$store.dispatch('itemEndProduct/update', this.itemProduct)
      } else {
        const quantity = this.itemProduct.attributes.quantity
        for (let i = 0; i < quantity; i++) {
          await this.$store.dispatch('itemEndProduct/create', this.itemProduct)
        }
      }
      // const { id, type } = response.data
      // await this.createDocument(id, type, 'Attachment')
      this.isLoading = false
      this.$router.go(-1)
    }
  }
}
</script>
