<template>
  <div class="mt-5">
    <base-file-input resource-type="item" />

    <base-field-input
      v-model="item.attributes.quantity"
      type="number"
      label="Units"
    />
    <base-autocomplete
      v-model="item.attributes.name"
      label="Part #"
      :items="partsCategories"
    />

    <base-autocomplete
      v-model="item.attributes.customAttributes.specie"
      label="Specie"
      :items="species"
      return-object
    />
    <base-autocomplete
      v-model="item.attributes.customAttributes.category"
      label="Category"
      :items="categories"
      :item-text="item => $t(`item.${item}`)"
    />
    <base-autocomplete
      v-model="item.attributes.customAttributes.origin"
      label="Origin"
      :items="countries"
    />
    <v-row justify="start">
      <v-checkbox
        v-model="item.attributes.customAttributes.cites"
        class="mx-4"
        label="Cites"
      ></v-checkbox>
      <v-checkbox
        v-model="item.attributes.customAttributes.fsc"
        class="mx-4"
        label="FSC Chain Of Custody"
      ></v-checkbox>
    </v-row>
  </div>
</template>

<script>
import {
  PARTS_CATEGORIES,
  SPECIES,
  CATEGORIES,
  COUNTRIES
} from '@/common/terms.js'
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      partsCategories: PARTS_CATEGORIES,
      species: SPECIES,
      categories: CATEGORIES
      // countries: COUNTRIES
    }
  },
  computed: {
    countries() {
      return this._.orderBy(
        COUNTRIES,
        [c => this.$t(`country.${c.text}`)],
        ['asc']
      )
    }
  }
}
</script>
